import {Alert, AlertDescription, AlertTitle} from '~/components/ui/alert';
import {AlertCircleIcon} from 'lucide-react';
import {Scramble} from '~/components/scramble';
import {StyledLink} from '~/components/link';
import {useRouteLoaderData} from '@remix-run/react';
import {loader as rootLoader} from '~/root';

type Props = {
  className?: string;
};

export const AlertNewWebsite = ({className}: Props) => {
  const {account} = useRouteLoaderData<typeof rootLoader>('root');
  return (
    !account && (
      <Alert variant="destructive" className={className}>
        <AlertCircleIcon className="size-4" />
        <AlertTitle>
          <Scramble>New website, who dis?</Scramble>
        </AlertTitle>
        <AlertDescription>
          We just made a new website! If you had an account, you will need to{' '}
          <StyledLink
            to={{
              pathname: '/kinde-auth/register',
              search: '?org_code=org_ad433b348a6e',
            }}
          >
            create a new one
          </StyledLink>
          .
        </AlertDescription>
        <AlertDescription>
          <strong>Use the same email</strong> you had last time and you'll find
          your games where you left them!
        </AlertDescription>
      </Alert>
    )
  );
};
